.section-title::after {
    content: "";
    /* position: relative; */ /* Add this if you want to put the line behind the text */
    display: block;
    margin: 0 auto; 
    width: 75%; 
    padding-top: 2px;
    border-bottom: 6px solid #ABDA72;
    /* transform: rotate(0deg); */
    /* z-index: -1; */
}