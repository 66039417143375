.swiper-pagination {
    bottom: 0;
    padding-top: 2px;
}

.swiper-pagination-bullet-active {
    background-color: #009005;
}

.swiper-button-prev,
.swiper-button-next {
    filter: drop-shadow(0.2px 1.5px 0.1rem rgba(0, 0, 0, 0.2));
    color: #009005;
}